import React from 'react';
import {
  Grid,
  Container,
  //Button
} from 'semantic-ui-react';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  margin-top: 50px;
  padding-top: 20px;
  min-height: 200px;
  background-color: #333;
  color: #fff;
  margin-bottom: 0;
`;

const Footer = () => {
  // TODO: adjust style
  return (
    <Wrapper>
      <Container>
        <Grid verticalAlign="middle">
          <Grid.Row centered>
            <Grid.Column mobile={12} tablet={8} computer={4} textAlign="left" width="30% !important">
              <div style={{ marginBottom: 10 }}>
                <LazyLoadImage
                  src="/assets/image/bonny_logo_white.svg"
                  width={120}
                />
              </div>
              達賚實業股份有限公司
              <br />
              <Link to="/terms">會員服務與條款</Link>
            </Grid.Column>
            <Grid.Column mobile={12} tablet={8} computer={4}>
              <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                {/*<Button circular color="facebook" icon="facebook" />
                <Button circular color="instagram" icon="instagram" />
                <Button circular color="youtube" icon="youtube" />*/}
              </div>
            </Grid.Column>
            <Grid.Column mobile={12} tablet={8} computer={4}>
              
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default Footer;
